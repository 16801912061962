import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./CreateList.css";

const EditList = () => {
  const [user] = useAuthState(auth);
  const { id } = useParams();
  const navigate = useNavigate();
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleEditList = (event) => {
    event.preventDefault();
    const listName = event.target.listName.value;

    // Client-side validation
    if (!listName) {
      toast.error("List name cannot be empty.");
      return;
    }

    setLoading(true);

    const updatedList = { listName };

    // Update the list via API
    const url = `https://huntcrowcloneone-36cbea3e4958.herokuapp.com/edit-list/${id}`;
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedList),
    })
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        toast.success("List updated successfully!");
        navigate("/my-leads");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error updating the list.");
        console.error("Error updating list:", error);
      });
  };

  // Fetch list data on mount
  useEffect(() => {
    setLoading(true);
    fetch(`https://huntcrowcloneone-36cbea3e4958.herokuapp.com/list/${id}`)
      .then((res) => res.json())
      .then((info) => {
        setList(info);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error fetching the list data.");
        console.error("Error fetching list:", error);
      });
  }, [id]);

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center p-5">
      <div className="card shadow-lg p-4 rounded-lg w-100" style={{ maxWidth: '500px' }}>
        <h3 className="text-center mb-4">Edit Your List</h3>
        <form onSubmit={handleEditList} className="form-container">
          <div className="mb-4">
            <label htmlFor="listName" className="form-label">
              List Name
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="listName"
              id="listName"
              defaultValue={list.listName || ""}
              placeholder="Enter list name"
              required
            />
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className={`btn btn-primary w-100 p-3 ${loading ? 'disabled' : ''}`}
              disabled={loading}
            >
              {loading ? "Updating..." : "Update List"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditList;
