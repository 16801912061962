// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCeICarZ4rmoj44gSz_y4VtLMiiyxCvAao",
  authDomain: "huntcrowcloneone.firebaseapp.com",
  projectId: "huntcrowcloneone",
  storageBucket: "huntcrowcloneone.firebasestorage.app",
  messagingSenderId: "325111941455",
  appId: "1:325111941455:web:aa96d9af7e577d40825d3a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;