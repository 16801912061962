import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import Testimonials from "../components/HomePage/Testimonials";
import Pricing from "../components/HomePage/Pricing";
import FeaturesPage from "./FeaturesPage";
import About from "../components/HomePage/About";
import ContactUs from "./ContactUs";
import BenefitArea from "../components/HomePage/BenefitArea";
import CtaArea from "../components/HomePage/CtaArea";
import MainFeature from "../components/HomePage/MainFeature";
import LeadGif from "../components/HomePage/LeadGif";

const Home = () => {
  const [notifications, setNotifications] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState([]);

  // Simulated order data
  const orderData = [
    {
      name: "John",
      location: "New York",
      time: "21 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "5,000 Credits",
    },
    {
      name: "Emma",
      location: "London",
      time: "23 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "10,000 Credits",
    },
    {
      name: "Liam",
      location: "Sydney",
      time: "20 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "40,000 Credits",
    },
    {
      name: "Sophia",
      location: "Toronto",
      time: "18 Hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "10,000 Credits",
    },
    {
      name: "Oliver",
      location: "Berlin",
      time: "19 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "10,000 Credits",
    },
    {
      name: "Mia",
      location: "Paris",
      time: "11 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "5,000 Credits",
    },
    {
      name: "Noah",
      location: "Dubai",
      time: "15 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "10,000 Credits",
    },
    {
      name: "Ava",
      location: "Singapore",
      time: "18 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "5,000 Credits",
    },
    {
      name: "Lucas",
      location: "Mumbai",
      time: "12 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "10,000 Credits",
    },
    {
      name: "Isabella",
      location: "Tokyo",
      time: "20 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "5,000 Credits",
    },
    {
      name: "Jack",
      location: "Kuala Lumpur",
      time: "22 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "5,000 Credits",
    },
    {
      name: "Hossain",
      location: "London",
      time: "20 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "40,000 Credits",
    },
    {
      name: "Katie",
      location: "Los Angeles",
      time: "24 hours ago",
      profileImg: "https://img.freepik.com/free-vector/young-prince-traditional-attire_1308-174397.jpg",
      package: "5,000 Credits",
    },
  ];

  useEffect(() => {
    setNotifications(orderData);

    const interval = setInterval(() => {
      const randomOrders = [...orderData]
        .sort(() => 0.5 - Math.random()) // Shuffle array
        .slice(0, 1); // Take 5 random orders
      setVisibleNotifications(randomOrders);
    }, 12000); // Every 2 minutes

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div>
      <Banner />
      <FeaturesPage />
      <LeadGif />
      <MainFeature />
      <About />
      <BenefitArea />
      <CtaArea />
      <Testimonials />
      <Pricing />
      <ContactUs />

      {/* Notification Section */}
      <div className="recent-orders">
        {visibleNotifications.map((notification, index) => (
          <div key={index} className="order-notification">
            <img
              src={notification.profileImg}
              alt={notification.name}
              className="profile-img"
            />
            <div className="notification-content">
              <p>
                <strong>{notification.name}</strong>, from{" "}
                <strong>{notification.location}</strong>, Purchased{" "}
                <strong>{notification.package}</strong>
              </p>
              <span className="time">{notification.time}</span>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .recent-orders {
          position: fixed;
          bottom: 20px;
          left: 20px;
          z-index: 9999;
          max-width: 300px;
        }
        .order-notification {
          display: flex;
          align-items: center;
          background-color:rgb(244, 234, 253);
          color: #333;
          padding: 10px 15px;
          margin-bottom: 10px;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.98);
          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        }
        .order-notification:hover {
          transform: scale(1.05);
          opacity: 0.9;
        }
        .profile-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .notification-content p {
          margin: 0;
          font-size: 14px;
          line-height: 1.5;
        }
        .notification-content .time {
          font-size: 12px;
          color: #777;
        }
      `}</style>
    </div>
  );
};

export default Home;
