// import React, { useEffect, useRef, useState } from "react";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { useNavigate, useParams } from "react-router-dom";
// import auth from "../firebase.init";
// import Loading from "../components/Shared/Loading";

// const CancelledPayment = () => {
//   const { id } = useParams();
//   const [user] = useAuthState(auth);
//   const navigate = useNavigate();
//   const formRef = useRef(null);

//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     const updatePaymentStatus = async () => {
//       try {
//         const updateOrder = { paymentStatus: "Cancelled" };
//         const url = `https://huntcrowcloneone-36cbea3e4958.herokuapp.com/payment-cancelled/${id}`;
//         const response = await fetch(url, {
//           method: "PUT",
//           headers: {
//             "content-type": "application/json",
//           },
//           body: JSON.stringify(updateOrder),
//         });

//         if (response.ok) {
//           setTimeout(() => {
//             formRef.current.submit();
//             setLoading(false);
//             navigate("/user-dashboard");
//           }, 1500);
//         } else {
//           console.error("Payment update failed.");
//           setLoading(false);
//         }
//       } catch (error) {
//         console.error("An error occurred:", error);
//         setLoading(false);
//       }
//     };

//     updatePaymentStatus();
//   }, [id, navigate]);

//   return (
//     <div>
//       <div>
//         {loading ? (
//           <div>
//             <Loading></Loading>
//           </div>
//         ) : (
//           <section className="testimonials s2">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="testimonials__main">
//                     <div className="block-text center">
//                       <h4 className="heading">
//                         You have cancelled the payment
//                       </h4>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         )}
//         <form
//           ref={formRef}
//           id="cancelPaymentForm"
//           onSubmit={(event) => event.preventDefault()}
//         >
//           <input type="text" hidden name="paymentStatus" value="Cancelled" />
//           <input type="submit" hidden value="Cancel Payment Now" />
//         </form>
//       </div>
//     </div>
//   );
// };

// export default CancelledPayment;






import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../firebase.init";
import Loading from "../components/Shared/Loading";

const CancelledPayment = () => {
  const { id } = useParams();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Handle cancel payment click
  const handleCancelPayment = async () => {
    setLoading(true);

    try {
      const updateOrder = { paymentStatus: "Cancelled" };
      const url = `https://huntcrowcloneone-36cbea3e4958.herokuapp.com/payment-cancelled/${id}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(updateOrder),
      });

      if (response.ok) {
        setTimeout(() => {
          setLoading(false);
          navigate("/user-dashboard");
        }, 1500);
      } else {
        console.error("Payment update failed.");
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f4f7fc",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            maxWidth: "400px",
            width: "100%",
          }}
        >
          <h4
            style={{
              fontSize: "24px",
              color: "#333",
              marginBottom: "20px",
            }}
          >
            You are about to cancel the payment.
          </h4>
          <button
            onClick={handleCancelPayment}
            style={{
              backgroundColor: "#e74c3c",
              color: "white",
              border: "none",
              padding: "15px 30px",
              fontSize: "18px",
              fontWeight: "bold",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#c0392b")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#e74c3c")}
          >
            Cancel Payment Now
          </button>
        </div>
      )}
    </div>
  );
};

export default CancelledPayment;
