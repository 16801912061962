import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import auth from "../../firebase.init";
import { useAuthState } from "react-firebase-hooks/auth";
import PageHero from "./PageHero";
import {
  HandCoins,
  LayoutDashboard,
  UserPen,
  UserRoundSearch,
} from "lucide-react";

const NavBar = () => {
  const [logo, setLogo] = useState({});
  const [user] = useAuthState(auth);
  const [admin, setAdmin] = useState([]);
  const location = useLocation();
  const [profile, setProfile] = useState([]);
  const navigate = useNavigate();

  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Sign-out error:", error);
      });
  };

  useEffect(() => {
    fetch(`https://huntcrowcloneone-36cbea3e4958.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info[0]));
  }, []);

  useEffect(() => {
    fetch(`https://huntcrowcloneone-36cbea3e4958.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setAdmin(info));
  }, []);

  useEffect(() => {
    fetch(`https://huntcrowcloneone-36cbea3e4958.herokuapp.com/profiles/`)
      .then((res) => res.json())
      .then((info) => setProfile(info));
  }, []);

  const isAdmin = user && admin.some((adm) => adm.userEmail === user.email);
  const isHomePage = location.pathname === "/";
  const shouldRenderPageHero = !isHomePage;

  // Navigation items based on login status
  const getNavItems = () => {
    if (user) {
      return [
        { to: "/find-leads", text: "Find Leads" },
        { to: "/my-leads", text: "My Leads" },
        { to: "/create-list", text: "Create List" },
        { to: "/email-templates", text: "Email Templates" },
        { to: "/pricing", text: "Buy Credits" },
        { to: "/contact", text: "Support" },
      ];
    }
    return [
      { to: "/", text: "Home" },
      { to: "/pricing", text: "Pricing" },
      { to: "/why-choose-us", text: "Why Choose Us" },
      { to: "/contact", text: "Contact Us" },
    ];
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <header>
        <nav
          className="navbar bg-white mobile-sidenav navbar-sticky navbar-default validnavs navbar-fixed white no-background"
          style={{
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        >
          <div className="container d-flex justify-content-between align-items-center">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i className="fa fa-bars" />
              </button>
              <Link className="navbar-brand" to="/">
                <img
                  src="https://i.postimg.cc/zBTqKdkD/Hunter-Logo.png"
                  className="logo logo-display"
                  alt="Logo"
                />
                <img
                  src="https://i.postimg.cc/zBTqKdkD/Hunter-Logo.png"
                  className="logo logo-scrolled"
                  alt="Logo"
                />
              </Link>
            </div>

            <div className="collapse navbar-collapse" id="navbar-menu">
              <img
                src="https://i.postimg.cc/zBTqKdkD/Hunter-Logo.png"
                alt="Logo"
              />
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i className="fa fa-times" />
              </button>
              <ul
                className="nav navbar-nav navbar-center"
                data-in="fadeInDown"
                data-out="fadeOutUp"
              >
                {getNavItems().map((item, index) => (
                  <li className="dropdown" key={index}>
                    <Link className="text-dark" to={item.to}>
                      {item.text}
                    </Link>
                  </li>
                ))}

                <li
                  style={{
                    display: windowWidth < 768 ? "block" : "none",
                  }}
                >
                  {isAdmin && (
                    <>
                      <Link to="/admin/dashboard">Admin Dashboard</Link>
                    </>
                  )}
                </li>
                <li
                  style={{
                    display: windowWidth < 768 ? "block" : "none",
                  }}
                >
                  {profile.map((pro) =>
                    pro.userEmail === user?.email ? (
                      <>
                        <Link to="/user-dashboard">
                          <LayoutDashboard /> Dashboard
                        </Link>
                      </>
                    ) : null
                  )}
                </li>
                <li
                  style={{
                    display: window.innerWidth < 768 ? "block" : "none",
                  }}
                >
                  {user ? (
                    <Link
                      onClick={handleSignout}
                      className="text-center"
                      style={{
                        backgroundColor: "rgb(51, 102, 255)",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "5px",
                      }}
                    >
                      Sign Out
                    </Link>
                  ) : (
                    <Link
                      to="/login"
                      className="text-center"
                      style={{
                        backgroundColor: "rgb(51, 102, 255)",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "5px",
                      }}
                    >
                      Login
                    </Link>
                  )}
                </li>
              </ul>
            </div>

            <div className="attr-right">
              <div className="d-flex">
                {user ? (
                  <>
                    {profile.map((pro) =>
                      pro.userEmail === user?.email ? (
                        <div className="nav-item dropdown" key={pro._id}>
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={
                                pro.profileImg ||
                                "https://st3.depositphotos.com/15648834/17930/v/450/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                              }
                              alt="Profile"
                              className="rounded-circle me-2"
                              style={{ width: "40px", height: "40px" }}
                            />
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end p-3">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/user-dashboard"
                              >
                                <LayoutDashboard /> Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/pending-payment/"
                              >
                                <HandCoins /> Pending Payment
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/find-leads">
                                <UserRoundSearch /> Find Leads
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/update-profile/${pro._id}`}
                              >
                                <UserPen /> Edit Profile
                              </Link>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <button
                                className="button"
                                onClick={handleSignout}
                              >
                                <i className="fa-solid fa-right-from-bracket text-primary"></i>{" "}
                                Logout
                              </button>
                            </li>
                          </ul>
                        </div>
                      ) : null
                    )}
                    {isAdmin && (
                      <div className="attr-right">
                        <div className="attr-nav">
                          <ul>
                            <li className="button">
                              <Link to="/admin/dashboard">Admin</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="overlay-screen" />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="attr-right">
                    <div className="attr-nav">
                      <ul>
                        <li className="button">
                          <Link to="/login">Login</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="overlay-screen" />
                  </div>
                )}
              </div>
              <div className="overlay-screen" />
            </div>
          </div>
        </nav>
      </header>
      {shouldRenderPageHero && <PageHero />}
    </>
  );
};

export default NavBar;
